import React from 'react';

export default function PrivacyPolicy() {
    return(
        <div style={{margin: '5% 5%'}}>
            <b>Privacy Notice</b>
            <br></br>
            <p>
            {" "}
            This privacy notice discloses the privacy practices for
            Senior Connex. This privacy notice applies solely to
            information collected by this website. It will notify you of
            the following:
            </p>
            <p>
            What personally identifiable information is collected from
            you through the website, how it is used and with whom it may
            be shared.
            <br />
            What choices are available to you regarding the use of your
            data.
            <br />
            The security procedures in place to protect the misuse of
            your information.
            <br />
            How you can correct any inaccuracies in the information.
            <br />
            Information Collection, Use, and Sharing
            <br />
            We are the sole owners of the information collected on this
            site. We only have access to/collect information that you
            voluntarily give us via email or other direct contact from
            you. We will not sell or rent this information to anyone.
            </p>
            <p>
            We will use your information to respond to you, regarding
            the reason you contacted us. We will not share your
            information with any third party outside of our
            organization, other than as necessary to fulfill your
            request, e.g. to ship an order.
            </p>

            <p>
            Unless you ask us not to, we may contact you via email in
            the future to tell you about specials, new products or
            services, or changes to this privacy policy.
            </p>

            <p>
            Your Access to and Control Over Information
            <br />
            You may opt out of any future contacts from us at any time.
            You can do the following at any time by contacting us via
            the email address or phone number given on our website:
            </p>

            <p>
            See what data we have about you, if any.
            <br />
            Change/correct any data we have about you.
            <br />
            Have us delete any data we have about you..
            <br />
            Express any concern you have about our use of your data..
            <br />
            Security.
            <br />
            We take precautions to protect your information. When you
            submit sensitive information via the website, your
            information is protected both online and offline.
            </p>

            <p>
            While we use encryption to protect sensitive information
            transmitted online, we also protect your information
            offline. Only employees who need the information to perform
            a specific job (for example, billing or customer service)
            are granted access to personally identifiable information.
            The computers/servers in which we store personally
            identifiable information are kept in a secure environment.
            </p>
        </div>
    )
}
