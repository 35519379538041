import React, { useState,useEffect }  from "react";
import { useHistory} from "react-router-dom";
import {
  Button,
} from "react-bootstrap";
import "./Signup.css";
import { toast } from 'react-toastify';

export default function Vital() {
  const history = useHistory();
  const [vitals,setVitals] = useState("");
  const [users,setUsers] = useState(JSON.parse(localStorage.getItem("users")));
  const [senior,setSenior] = useState("");

  useEffect(() => {
    if(users === null)
    {
        async function fetchData() {
            const res = await fetch("https://seniorconnex.com/prod/user/getMemberList",{
                method: "GET",
                headers: {
                'Content-Type': "application/json",
                "Authorization": localStorage.getItem("token")
                },
            });
            res.json().then(res => {
              localStorage.setItem("users", JSON.stringify(res.result.list));
              setUsers(res.result.list);
              setSenior(res.result.list.find(usr => usr.userRole === 1))
            })
            .catch(err => console.error(err));
        }
        fetchData();
    }
    else {
      if(senior === "")
        setSenior(users.find(usr => usr.userRole === 1))
      if(vitals === "" && senior !== "")
      {
        if(senior === undefined)
        {
          toast.dismiss();
          toast.error("Please Add Senior First",{toastId: "senior_error"});
        }
        else{
          async function fetchData() {
            const res = await fetch("https://seniorconnex.com/prod/health/getAlertParam",{
                method: "POST",
                headers: {
                'Content-Type': "application/json",
                "Authorization": localStorage.getItem("token")
                },
                body: JSON.stringify({
                  senior: senior._id
                })
            });
            res.json().then(res => {
              localStorage.setItem("vitals", JSON.stringify(res.result.alertParam));
              setVitals(res.result.alertParam);
            })
            .catch(err => console.error(err));
          }
          fetchData();
        }
      }
    }
  },[users,vitals,senior]);

  function handleEdit(event) {
    event.preventDefault();
    history.push("/edit_vital");
  }

  return (
    <div>
      <table className="table table-bordered table-striped" style={{margin: '5% auto', maxWidth: '50%'}}>
          <thead>
              <tr>
                <th>Vital</th>
                <th>Min. Value</th>
                <th>Max. Value</th>
              </tr>
          </thead>
          <tbody>
              <tr key="0">
                <td>Heart Rate</td>
                <td>{vitals !== "" && vitals.length !== 0 && vitals.find(a => a.vital === "heartRate").minValue}</td>
                <td>{vitals !== "" && vitals.length !== 0 && vitals.find(a => a.vital === "heartRate").maxValue}</td>
              </tr>
              <tr key="1">
                <td>Blood Pressure (Diastolic)</td>
                <td>{vitals !== "" && vitals.length !== 0 && vitals.find(a => a.vital === "diastolicBp").minValue}</td>
                <td>{vitals !== "" && vitals.length !== 0 && vitals.find(a => a.vital === "diastolicBp").maxValue}</td>
              </tr>
              <tr key="2">
                <td>Blood Pressure (Systolic)</td>
                <td>{vitals !== "" && vitals.length !== 0 && vitals.find(a => a.vital === "systolicBp").minValue}</td>
                <td>{vitals !== "" && vitals.length !== 0 && vitals.find(a => a.vital === "systolicBp").maxValue}</td>
              </tr>
              {/* <tr key="3">
                <td>Step Count</td>
                <td>{vitals !== "" && vitals.length !== 0 && vitals.find(a => a.vital === "stepsCount").minValue}</td>
                <td></td>
              </tr>
              <tr key="4">
                <td>Stand Hours</td>
                <td>{vitals !== "" && vitals.length !== 0 && vitals.find(a => a.vital === "standHour").minValue}</td>
                <td></td>
              </tr> */}
              <tr key="5">
                <td>Oxygen Level</td>
                <td>{vitals !== "" && vitals.length !== 0 && vitals.find(a => a.vital === "oxygenLevel").minValue}</td>
                <td></td>
              </tr>
              <tr>
                  <td colSpan="3" style={{textAlign: "center"}}>
                      <Button bsSize="large" disabled={senior === "" || senior === undefined } onClick={handleEdit} style={{backgroundColor: "#1d75bd", color: "#ffffff",width: "25%"}}>
                          Edit
                      </Button>
                  </td>
              </tr>
          </tbody>
      </table>
    </div>
  );
}