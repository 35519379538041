import React from 'react';
import ReactPlayer from 'react-player';
import './Home.css';
import logo from '../images/SCLogo.png';
import video from '../video/SCVideo2.mp4';

export default function Home(){
    return(
        <div className="Home">
            <div className="fl-page">
                <div className="fl-page-content">
                    <div className="fl-content-full col-md-12">
                        <div className="row">
                            <div className="fl-content col-md-12">
                                <article className="fl-post post-156 page type-page status-publish">
                                    <div className="fl-post-content">
                                        <div className="fl-builder-content fl-builder-content-156 fl-builder-content-primary fl-builder-global-templates-locked">
                                            <div className="fl-row fl-row-full-width fl-row-bg-color fl-node-5b88364b16fa5">
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">            
                                                        <div className="fl-col-group fl-node-5b88364b16ef6">
                                                            <div className="fl-col fl-node-5b88364b16d17">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-rich-text fl-node-5b91a92f0cba6 fl-animation fl-slide-up fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-rich-text">
                                                                                <p>
                                                                                    <img className="size-full aligncenter" src={logo} alt="" width="640" height="467" sizes="(max-width: 640px) 100vw, 640px" />
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="fl-module fl-module-heading fl-node-5bfcc21b2a1c8 fl-animation fl-slide-left fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <h1 className="fl-heading">
                                                                                <span className="fl-heading-text">We keep families <b>connected</b>!</span>
                                                                            </h1>
                                                                        </div>
                                                                    </div>
                                                                    <div className="fl-module fl-module-heading fl-node-5bfcc24522a4f fl-animation fl-slide-right fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <h1 className="fl-heading">
                                                                                <span className="fl-heading-text">Enjoy <b>real-time,</b></span>
                                                                            </h1>
                                                                        </div>
                                                                    </div>
                                                                    <div className="fl-module fl-module-heading fl-node-5bfcc260bfd31 fl-animation fl-slide-up fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <h1 className="fl-heading">
                                                                                <span className="fl-heading-text"><b>face-to-face</b> conversations!</span>
                                                                            </h1>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fl-row fl-row-fixed-width fl-row-bg-none">
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                                        <div className="fl-col-group">
                                                            <div className="fl-col fl-node-5bfcc6cae03bf">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-separator fl-node-5bfcc6ca3f004 fl-animation fl-slide-right fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-separator"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5bfccdf460b13">
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                                        <div className="fl-col-group fl-node-5bfccdf480180">
                                                            <div className="fl-col fl-node-5bfccdf4808c7">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-video fl-node-5bfccdf460221">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <ReactPlayer url={video} playing={false} controls={true} width="100%" height="100%" />
                                                                        </div>
                                                                        <div style={{textAlign: "center"}}>
                                                                            <a href="/access_beta"  className="btn btn-lg btn-default" style={{backgroundColor: "#1d75bd", color: "#ffffff"}}>Be a part of our BETA program</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5b88364b16d50">
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">        
                                                        <div className="fl-col-group fl-node-5b88364b16984">
                                                            <div className="fl-col fl-node-5b88364b16949">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-heading fl-node-5b88364b16f6b fl-animation fl-slide-left fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <h1 className="fl-heading">
                                                                                <span className="fl-heading-text"><b>Senior Connex: Your Family's Pathway to Lasting Connection</b></span>
                                                                            </h1>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="fl-col-group fl-node-5b88364b16c69">
                                                            <div className="fl-col fl-node-5b88364b16dc3 fl-col-small">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-callout fl-node-5b88364b16ee3 fl-animation fl-slide-right fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-callout fl-callout-center fl-callout-has-icon fl-callout-icon-above-title">
                                                                                <div className="fl-callout-content">
                                                                                    <span className="fl-icon">
                                                                                        <i className="fas fa-user-friends"></i>
                                                                                    </span>
                                                                                    <h1 className="fl-callout-title">
                                                                                        <span className="fl-callout-title-text">Face-to-Face</span>
                                                                                    </h1>
                                                                                    <div className="fl-callout-text-wrap">
                                                                                        <div className="fl-callout-text">
                                                                                            <h3><span style={{fontWeight: "400"}}>Enjoy real time, face-to-face conversations with your loved one living alone or in a senior-living facility. </span></h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="fl-col fl-node-5b88364b16dfd fl-col-small">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-callout fl-node-5b88364b16e70 fl-animation fl-slide-up fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-callout fl-callout-center fl-callout-has-icon fl-callout-icon-above-title">
                                                                                <div className="fl-callout-content">
                                                                                    <span className="fl-icon">
                                                                                        <i className="fas fa-users"></i>
                                                                                    </span>
                                                                                    <h1 className="fl-callout-title"><span className="fl-callout-title-text">Family Platform</span></h1>
                                                                                    <div className="fl-callout-text-wrap">
                                                                                        <div className="fl-callout-text">
                                                                                            <h3><span style={{fontWeight: "400"}}>The Senior Connex Family platform helps families stay connected surrounding their senior’s needs</span></h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="fl-col fl-node-5b88364b16bf6 fl-col-small">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-callout fl-node-5b88364b16eaa fl-animation fl-slide-down fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-callout fl-callout-center fl-callout-has-icon fl-callout-icon-above-title">
                                                                                <div className="fl-callout-content">
                                                                                    <span className="fl-icon">
                                                                                        <i className="fas fa-user-md"></i>
                                                                                    </span>
                                                                                    <h1 className="fl-callout-title"><span className="fl-callout-title-text">Be There</span></h1>
                                                                                    <div className="fl-callout-text-wrap">
                                                                                        <div className="fl-callout-text">
                                                                                            <h3><span style={{fontWeight: "400"}}>Senior Connex technology lets you be there for conferencing with medical and nursing staff.</span></h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5bfcaadad6ffe">
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                                        <div className="fl-col-group fl-node-5bfcaadae3fa3">
                                                            <div className="fl-col fl-node-5bfcaadae43b6">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-separator fl-node-5bfca75d3e322">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-separator"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5bfca75d3e67b">
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                                        <div className="fl-col-group fl-node-5bfca75d4672f">
                                                            <div className="fl-col fl-node-5bfca75d46b4f fl-col-has-cols">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-col-group fl-node-5bfca9ce33667 fl-col-group-nested">
                                                                        <div className="fl-col fl-node-5bfca9ce33a46 fl-col-small">
                                                                            <div className="fl-col-content fl-node-content">
                                                                                <div className="fl-module fl-module-icon fl-node-5bfcaa23e0c72 fl-visible-desktop-medium fl-animation fl-slide-left fl-animated">
                                                                                    <div className="fl-module-content fl-node-content">
                                                                                        <div className="fl-icon-wrap">
                                                                                            <span className="fl-icon">
                                                                                                <i className="fas fa-mobile-alt"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="fl-col fl-node-5bfca9ce33a8b fl-col-small">
                                                                            <div className="fl-col-content fl-node-content">
                                                                                <div className="fl-module fl-module-rich-text fl-node-5bfcaa03bdb7d fl-animation fl-slide-right fl-animated">
                                                                                    <div className="fl-module-content fl-node-content">
                                                                                        <div className="fl-rich-text">
                                                                                            <h2><span style={{fontWeight: "400"}}>Senior Connex App:</span></h2>
                                                                                            <ul>
                                                                                                <li style={{fontWeight: "400"}}>
                                                                                                    <h2><span style={{fontWeight: "400"}}>Direct Access to Senior Connex Video/Audio Hot Spots</span></h2>
                                                                                                </li>
                                                                                                <li style={{fontWeight: "400"}}>
                                                                                                    <h2><span style={{fontWeight: "400"}}>Senior Connex Family Network platform where family members discuss the issues surrounding their loved ones</span></h2>
                                                                                                </li>
                                                                                                <li style={{fontWeight: "400"}}>
                                                                                                    <h2><span style={{fontWeight: "400"}}>Medication Management/Doctor Visit/Meal Scheduling</span></h2>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5bfcab6545c50">
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                                        <div className="fl-col-group fl-node-5bfcab655b41d">
                                                            <div className="fl-col fl-node-5bfcab655b946">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-separator fl-node-5bfcab6545800">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-separator"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5bfca78e20305" >
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                                        <div className="fl-col-group fl-node-5bfca78e2d79d" >
                                                            <div className="fl-col fl-node-5bfca78e2da50" >
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-rich-text fl-node-5bfcab8eda252 fl-animation fl-slide-up fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-rich-text">
                                                                                <h2><em><span style={{fontWeight: "400"}}>With the cost of travel (in time and money), Senior Connex technology allows you to “be there” when and as often as you want. &nbsp;You can stay involved and make sure mom and dad are safe and doing well.</span></em></h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5bfcabed7b39a" >
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                                        <div className="fl-col-group fl-node-5bfcabed8e9d5" >
                                                            <div className="fl-col fl-node-5bfcabed8f2bd" >
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-separator fl-node-5bfcabed7aee9" >
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-separator"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5bfcac213064e">
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                                        <div className="fl-col-group fl-node-5bfcac21419b2">
                                                            <div className="fl-col fl-node-5bfcac2142006">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-callout fl-node-5bfcac212fde3 fl-animation fl-slide-up fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-callout fl-callout-center fl-callout-has-icon fl-callout-icon-below-title">
                                                                                <div className="fl-callout-content">
                                                                                    <h1 className="fl-callout-title"><span className="fl-callout-title-text">What’s the Difference?</span></h1>
                                                                                    <span className="fl-icon">
                                                                                        <i className="fas fa-globe"></i>
                                                                                    </span>
                                                                                    <div className="fl-callout-text-wrap">
                                                                                        <div className="fl-callout-text">
                                                                                            <h2 style={{textAlign: "left"}}><span style={{fontWeight: "400"}}>Apps like Skype and Facetime are great tools, but cannot be described as Visual Touch™ in that they are not always “on” and your loved one most likely is not a technology guru. &nbsp;Once the Senior Connex hotspots are in place all you do is connect via the Senior Connex app and say “hi.” The Senior Connex app and in-home hotspots are intuitive, attractive, and work.</span></h2>
                                                                                            <h2 style={{textAlign: "left"}}><span style={{fontWeight: "400"}}>With Senior Connex, you can:</span></h2>
                                                                                            <ul>
                                                                                                <li style={{fontWeight: "400", textAlign: "left"}}>
                                                                                                    <h2><span style={{fontWeight: "400"}}>Expand your senior’s social interaction</span></h2>
                                                                                                </li>
                                                                                                <li style={{fontWeight: "400", textAlign: "left"}}>
                                                                                                    <h2><span style={{fontWeight: "400"}}>Remotely coordinate caregiver services from afar. &nbsp;</span></h2>
                                                                                                </li>
                                                                                                <li style={{fontWeight: "400", textAlign: "left"}}>
                                                                                                    <h2><span style={{fontWeight: "400"}}>Have a real presence any time</span></h2>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5bfcad707ea67">
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                                        <div className="fl-col-group fl-node-5bfcad70932da">
                                                            <div className="fl-col fl-node-5bfcad7093674 fl-col-small">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-button fl-node-5bfcad707e480 fl-animation fl-slide-up fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-button-wrap fl-button-width-full fl-button-left">
                                                                                <a href="\" target="_self" className="fl-button" role="button">
                                                                                    <span className="fl-button-text">Senior Living Facilities</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="fl-module fl-module-rich-text fl-node-5bfcae02e0d35 fl-animation fl-slide-left fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-rich-text">
                                                                                <h2>Senior Connex is on the leading edge of medical care. &nbsp;As a greater percentage of the population ages and the costs of supporting our seniors ever increases, technology will become more and more a necessity. &nbsp;Senior Connex provides the technology for senior living facilities and in-home health agencies to reduce costs while still providing the needed care.</h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="fl-col fl-node-5bfcae967fddf fl-col-small">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-button fl-node-5bfcae96801ff fl-animation fl-slide-down fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-button-wrap fl-button-width-full fl-button-left">
                                                                                <a href="\" target="_self" className="fl-button" role="button">
                                                                                    <span className="fl-button-text">What about Families?</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="fl-module fl-module-rich-text fl-node-5bfcae9680240 fl-animation fl-slide-up fl-animated">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-rich-text">
                                                                                <h2><span style={{fontWeight: "400"}}>As families take on greater roles in their senior’s care, Senior Connex technology will be there, allowing your senior to stay in their home longer with peace of mind.</span></h2>
                                                                                <h2><span style={{fontWeight: "400"}}>Baby boomers are comfortable with most low-level, simple technology tasks and welcome the interaction and safety technology provides.&nbsp;</span></h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5bfcae681a248">
                                                <div className="fl-row-content-wrap">
                                                    <div className="fl-row-content fl-row-fixed-width fl-node-content">
                                                        <div className="fl-col-group fl-node-5bfcae682fd37">
                                                            <div className="fl-col fl-node-5bfcae6830382">
                                                                <div className="fl-col-content fl-node-content">
                                                                    <div className="fl-module fl-module-separator fl-node-5bfcae6819c94">
                                                                        <div className="fl-module-content fl-node-content">
                                                                            <div className="fl-separator"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="fl-page-footer col-md-6 text-center">
                    <div className="fl-page-footer-text">Copyright Senior Connex, 2018</div>
                </div>
                <div className="fl-page-footer col-md-3 text-right"></div>
                <div className="fl-page-footer col-md-3 text-left">
                    <div className="fl-page-footer-text">
                        <b>Contact Us</b> <br/>
                        Email: info@seniorconnex.com <br/>
                        Phone: +1 509-521-3124
                    </div>
                </div>
            </footer>
        </div>
    )
}