import React, { useState } from "react";
import { useHistory} from "react-router-dom";
import {
  Button,
  FormControl
} from "react-bootstrap";
import "./Signup.css";
import { toast } from 'react-toastify';

export default function Vital() {
  const history = useHistory();
  const vitals = JSON.parse(localStorage.getItem("vitals"));
  const [heartLow,setHeartLow] = useState(vitals.length !== 0 && vitals.find(a => a.vital === "heartRate").minValue);
  const [heartHigh,setHeartHigh] = useState(vitals.length !== 0 && vitals.find(a => a.vital === "heartRate").maxValue);
  const [dBpLow,setDBpLow] = useState(vitals.length !== 0 && vitals.find(a => a.vital === "diastolicBp").minValue);
  const [dBpHigh,setDBpHigh] = useState(vitals.length !== 0 && vitals.find(a => a.vital === "diastolicBp").maxValue);
  const [sBpLow,setSBpLow] = useState(vitals.length !== 0 && vitals.find(a => a.vital === "systolicBp").minValue);
  const [sBpHigh,setSBpHigh] = useState(vitals.length !== 0 && vitals.find(a => a.vital === "systolicBp").maxValue);
  // const [stepMin,setStepMin] = useState(vitals.length !== 0 && vitals.find(a => a.vital === "stepsCount").minValue);
  // const [standMin,setStandMin] = useState(vitals.length !== 0 && vitals.find(a => a.vital === "standHour").minValue);
  const [oxLevel,setOxLevel] = useState(vitals.length !== 0 && vitals.find(a => a.vital === "oxygenLevel").minValue);
  const [disableSubmit,setDisableSubmit] = useState(false);
  const users = JSON.parse(localStorage.getItem("users"));
  const senior = users.find(usr => usr.userRole === 1);

  function handleSubmit(event) {
    event.preventDefault();
    setDisableSubmit(true);
    fetch("https://seniorconnex.com/prod/health/setAlertParam",{
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Authorization": localStorage.getItem("token")
      },
      body: JSON.stringify({
        senior: senior._id,
        healthAlertParam: [
          // {vital: "stepsCount",minValue: stepMin},
          // {vital: "standHour",minValue: standMin},
          {vital: "oxygenLevel",minValue: oxLevel},
          {vital: "heartRate",maxValue: heartHigh,minValue: heartLow},
          {vital: "diastolicBp",maxValue: dBpHigh,minValue: dBpLow},
          {vital: "systolicBp",maxValue: sBpHigh,minValue: sBpLow}
        ]
      })
    }).then(response=>{
      if(response.status === 200) {
          response.json().then(res => {
            toast.success(res.responseMessage,{toastId: "vital_success"});
            console.log(res);
            history.push("/vital");
          });
      }
      else{
        response.json().then(res => {
          setDisableSubmit(false);
          toast.error(res.responseMessage,{toastId: "vital_error"});
          console.log(res)
        });
      }
    }).catch((error) => { console.error(error); setDisableSubmit(true);});
  }

  function renderForm() {
    return (
        <form onSubmit={handleSubmit}>
            <table className="table table-bordered table-striped" style={{margin: '5% auto', maxWidth: '50%'}}>
                <thead>
                    <tr>
                      <th>Vital</th>
                      <th>Min. Value</th>
                      <th>Max. Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="0">
                      <td>Heart Rate<b style={{color: "red"}}>*</b></td>
                      <td>
                        <FormControl
                            type="number"
                            value={heartLow}
                            required={true}
                            min={0}
                            onChange={e => {
                              if(e.target.value < 0)
                                toast.error("Please enter positive value",{toastId: "neg_error"});
                              else{
                                setHeartLow(e.target.value)
                              }
                            }}
                        />
                      </td>
                      <td>
                        <FormControl
                            type="number"
                            value={heartHigh}
                            required={true}
                            min={0}
                            onChange={e => {
                              if(e.target.value < 0)
                                toast.error("Please enter positive value",{toastId: "neg_error"});
                              else{
                                setHeartHigh(e.target.value)
                              }
                            }}
                        />
                      </td>
                    </tr>
                    <tr key="1">
                      <td>Blood Pressure (Diastolic)<b style={{color: "red"}}>*</b></td>
                      <td>
                        <FormControl
                            type="number"
                            value={dBpLow}
                            required={true}
                            min={0}
                            onChange={e => {
                              if(e.target.value < 0)
                                toast.error("Please enter positive value",{toastId: "neg_error"});
                              else{
                                setDBpLow(e.target.value)
                              }
                            }}
                        />
                      </td>
                      <td>
                        <FormControl
                            type="number"
                            value={dBpHigh}
                            required={true}
                            min={0}
                            onChange={e => {
                              if(e.target.value < 0)
                                toast.error("Please enter positive value",{toastId: "neg_error"});
                              else{
                                setDBpHigh(e.target.value)
                              }
                            }}
                        />
                      </td>
                    </tr>
                    <tr key="2">
                      <td>Blood Pressure (Systolic)<b style={{color: "red"}}>*</b></td>
                      <td>
                        <FormControl
                            type="number"
                            value={sBpLow}
                            required={true}
                            min={0}
                            onChange={e => {
                              if(e.target.value < 0)
                                toast.error("Please enter positive value",{toastId: "neg_error"});
                              else{
                                setSBpLow(e.target.value)
                              }
                            }}
                        />
                      </td>
                      <td>
                        <FormControl
                            type="number"
                            value={sBpHigh}
                            required={true}
                            min={0}
                            onChange={e => {
                              if(e.target.value < 0)
                                toast.error("Please enter positive value",{toastId: "neg_error"});
                              else{
                                setSBpHigh(e.target.value)
                              }
                            }}
                        />
                      </td>
                    </tr>   
                    {/* <tr key="3">
                      <td>Step Count</td>
                      <td>
                        <FormControl
                            type="number"
                            value={stepMin}
                            onChange={e => setStepMin(e.target.value)}
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr key="4">
                      <td>Stand Hours</td>
                      <td>
                        <FormControl
                            type="number"
                            value={standMin}
                            onChange={e => setStandMin(e.target.value)}
                        />
                      </td>
                      <td></td>
                    </tr> */}
                    <tr key="5">
                      <td>Oxygen Level<b style={{color: "red"}}>*</b></td>
                      <td>
                        <FormControl
                            type="number"
                            value={oxLevel}
                            required={true}
                            min={0}
                            onChange={e => {
                              if(e.target.value < 0)
                                toast.error("Please enter positive value",{toastId: "neg_error"});
                              else{
                                setOxLevel(e.target.value)
                              }
                            }}
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                        <td colSpan="3" style={{textAlign: "center"}}>
                            <Button bsSize="large" disabled={disableSubmit} type="submit" style={{backgroundColor: "#1d75bd", color: "#ffffff",width: "25%",marginRight: "5%"}}>
                                Submit
                            </Button>
                            <Button bsSize="large" onClick={()=> history.push("/vital")} style={{backgroundColor: "#1d75bd", color: "#ffffff"}}>
                              Cancel
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </table>
      </form>
    );
  }

  return (
    <div>
      {renderForm()}
    </div>
  );
}