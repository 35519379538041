import React from "react";
import {Route,Switch} from 'react-router-dom';
import Home from './containers/Home';
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Invite from "./containers/Invite";
import Profile from "./containers/Profile";
import EditProfile from "./containers/EditProfile";
import EditSeniorProfile from "./containers/EditSeniorProfile";
import Download from "./containers/download";
import Signup from "./containers/Signup";
import AddSenior from "./containers/AddSenior";
import SeniorProfile from "./containers/SeniorProfile";
import ForgotPassword from "./containers/ForgotPassword";
import ChangePassword from "./containers/ChangePassword";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Members from "./containers/Members";
import UpdateNetwork from "./containers/UpdateNetwork";
import AddDevice from "./containers/AddDevice";
import AddWatch from "./containers/AddWatch";
import Vital from "./containers/Vital";
import EditVital from "./containers/EditVital";
import {useAppContext} from './libs/contextLib';

export default function Routes(){
    const { isAuthenticated } = useAppContext();
    const user = JSON.parse(localStorage.getItem("user"));
    return(
        <>
        {isAuthenticated ?
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/profile">
                    <Profile />
                </Route>
                <Route exact path="/edit_profile">
                    <EditProfile />
                </Route>
                <Route exact path="/change_password">
                    <ChangePassword />
                </Route>
                <Route exact path="/edit_senior_profile">
                    <EditSeniorProfile />
                </Route>
                {user.userRole === 0 ?
                <>
                <Route exact path="/invite">
                    <Invite />
                </Route>
                <Route exact path="/vital">
                    <Vital />
                </Route>
                <Route exact path="/edit_vital">
                    <EditVital />
                </Route>
                <Route exact path="/members">
                    <Members />
                </Route>
                <Route exact path="/add_senior">
                    <AddSenior />
                </Route>
                <Route exact path="/senior_profile">
                    <SeniorProfile />
                </Route>
                <Route exact path="/update_network">
                    <UpdateNetwork />
                </Route>
                <Route exact path="/add_device">
                    <AddDevice />
                </Route>
                <Route exact path="/add_watch">
                    <AddWatch />
                </Route>
                </>
                : ""
                }
                <Route>
                    <NotFound />
                </Route>
            </Switch> :
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/signup">
                    <Signup />
                </Route>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/access_beta">
                    <Download />
                </Route>
                <Route exact path="/forgot_password">
                    <ForgotPassword />
                </Route>
                <Route exact path="/privacy_policy">
                    <PrivacyPolicy />
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        }
        </>
    )
}