import { LinkContainer } from 'react-router-bootstrap';
import React, {useState, useEffect} from 'react';
import { Link, useHistory } from "react-router-dom";
import { Navbar, Nav, NavItem,NavDropdown } from "react-bootstrap";
import './App.css';
import Routes from './Routes';
import {AppContext} from './libs/contextLib';
import logo from './images/SCLogo.png';

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  useEffect(() => {
    onLoad();
  }, []);
  
  function onLoad() {
    try {
      if(localStorage.getItem("isloggedin"))
        userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
    setIsAuthenticating(false);
  }

  const history = useHistory();
  function handleLogout(){
    if(window.confirm('Are you sure?'))
    {
      userHasAuthenticated(false);
      localStorage.clear();
      history.push("/login");
    }
  }
  return (
    !isAuthenticating &&
    <div className="App" style={{width: "100%"}}>
      <Navbar fluid collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/"><img src={logo} alt="" style={{width: "10%", marginTop: "-2%"}} /></Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            {isAuthenticated ? 
            <>
              {JSON.parse(localStorage.getItem("user")).userRole === 0 &&
              <>
                <LinkContainer to="/members">
                  <NavItem>Members</NavItem>
                </LinkContainer>
                <LinkContainer to="/senior_profile">
                  <NavItem>Senior Profile</NavItem>
                </LinkContainer>
                <LinkContainer to="/update_network">
                  <NavItem>Social Connex Name</NavItem>
                </LinkContainer>
                <NavDropdown title="Settings" id="basic-nav-dropdown">
                  <LinkContainer to="/add_device">
                    <NavItem>Add Device</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/invite">
                    <NavItem>Add Caregiver</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/vital">
                    <NavItem>Vital's Setting</NavItem>
                  </LinkContainer>
                </NavDropdown>
              </>
              }
              <LinkContainer to="/profile">
                <NavItem>Profile</NavItem>
              </LinkContainer>
              <NavItem onClick={handleLogout}>Logout</NavItem> 
            </> : 
            <>
              <LinkContainer to="/access_beta">
                <NavItem>Access Beta</NavItem>
              </LinkContainer>
              {/*<LinkContainer to="/signup">*/}
              {/*  <NavItem>Create Network</NavItem>*/}
              {/*</LinkContainer>*/}
              <li>
                <a target='_blank' href={'https://admin.seniorconnex.com/'}>Login</a>
              </li>
            </>}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated}}>
        <Routes />
      </AppContext.Provider>
    </div>
  );
}

export default App;
